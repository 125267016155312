import apisauce from 'apisauce';

import { logHttpError } from '@lc/lib/api/apiConfig';
import { getUserAuthKey, removeUserData } from '@lc/lib/helpers/userData';

import logger from '../helpers/logger';

import { letClinicBaseUrl } from './config';

const urlToNotLogInSentry = ['v1/auth/login'];

const api = apisauce.create({
  baseURL: letClinicBaseUrl,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  },
  timeout: 45000,
});

api.addRequestTransform(request => {
  if (['localhost', 'letclinic'].some(domain => request?.baseURL?.indexOf(domain) !== -1)) {
    request.headers.Authorization = getUserAuthKey() || '';
  }
  // console.log('request: ', request);
});

const redirectUserForLogin = () => {
  if (process.browser) {
    const redirectUrl = `/login?returnUrl=${window.location.pathname}`;
    removeUserData();
    window.replace(redirectUrl);
    return false;
  }
};

const handleUnauthorized = async (response, noRedirect) => {
  if (noRedirect) {
    return [{ isHandled: true, error: response }, true];
  }
  redirectUserForLogin();
};

const handleError = error =>
  new Promise(resolve => {
    resolve({
      isHandled: false,
      error,
    });
  });

const handleResponse = (promise, noRedirect) =>
  promise
    .then(async response => {
      if (response.ok) {
        return [response.data, false];
      }
      if (!response.ok && logHttpError.has(response.problem)) {
        logHttpError.get(response.problem)('legacy', response.originalError);
      } else if (response.status !== 401 && !urlToNotLogInSentry.includes(response.config.url)) {
        logger.logError('handleResponse legacy nOK', response.originalError);
      }

      if (response.status === 401) {
        const res = await handleUnauthorized(response, noRedirect);
        return res;
      }

      const res = await handleError(response);
      return [res, true];
    })
    .catch(error => {
      logger.logError('handleResponse catch', error);

      return [{ isHandled: false, error }, true];
    });

export const post = (url, data, extras) => handleResponse(api.post(url, data, extras));
export const patch = (url, data, extras) => handleResponse(api.patch(url, data, extras));
export const get = (url, data, extras, noRedirect) =>
  handleResponse(api.get(url, data, extras), noRedirect);
export const put = (url, data, extras) => handleResponse(api.put(url, data, extras));
export const del = (url, data, extras) => handleResponse(api.delete(url, data, extras));
