import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints, spacing }) => ({
  root: {
    position: 'relative',
    width: '100%',
    maxWidth: '1136px',
    margin: '0 auto',
    padding: `0 ${spacing(2)}px`,
    overflowX: ({ overflowX }) => (overflowX ? 'auto' : 'unset'),
    [breakpoints.up('lg')]: {
      padding: '0',
      overflowX: 'unset!important',
    },
  },
  main: {
    minHeight: 'calc(100vh - 65px)',
    [breakpoints.up('md')]: {
      minHeight: 'calc(100vh - 332px)',
    },
  },
}));
