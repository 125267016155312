/* eslint-disable react/no-array-index-key */
import React from 'react';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const ViewDetail = ({ detail, close }) => (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 2000,
        backgroundColor: '#ffffff',
        width: '100%',
        height: '100%',
        overflow: 'auto',
      }}
    >
      <ArrowBackIos
        onClick={close}
        style={{
          marginTop: '40px',
          marginLeft: '24px',
          fontSize: '16px',
        }}
      />
      <div
        style={{
          padding: '24px',
        }}
      >
        {detail.title ? (
          <Typography style={{ marginBottom: '16px' }}>
            <b>{detail.title}</b>
          </Typography>
        ) : null}

        {detail.content}
      </div>
    </div>
  );
ViewDetail.propTypes = {
  close: PropTypes.func.isRequired,
  detail: PropTypes.object.isRequired,
};

export default ViewDetail;
