import cepApi from 'cep-promise';
import pagarme from 'pagarme';

import BRstates from 'helpers/states/BRstates.json';

import * as api from './api';
import * as config from './config';
import sharedCacheClient from './cache';
import socialAPI from './socialAPI';
import googleAPI from './googleAPI';
import nextjs from './nextjsAPI';

export default {
  config,

  getCNPJInfo: id => api.get(`v1/cnpj/${id}`),
  saveOccupationArea: data => api.post('v1/occupation-area', data),
  updateOccupationArea: (id, data) => api.put(`v1/occupation-area/${id}`, data),
  saveAddress: data => api.post('v1/contacts/address', data),
  getSecondaryRoles: () => api.get('v1/secondary-role'),
  getAddressByZipCode: cep => cepApi(cep),

  getProfileData: () => api.get('v1/auth/profileData'),
  getProfile: id => api.get(`v1/profiles/${id}`),
  getAllStatesBr: () => Promise.resolve([BRstates, false]),
  getCityByState: async id =>
    Promise.resolve([
      await import(`../helpers/states/cities/${id}.json`).then(module => module.default),
      false,
    ]),

  saveDocuments: documents => {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return api.put('v1/profiles/documents', documents, { headers });
  },

  savePhotoProfile: file => {
    const form = new FormData();
    form.append('files', file);
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return api.put('v1/users/avatar', form, { headers });
  },

  getTerm: id => api.get(`v1/terms-information/${id}`),

  /* login */
  login: (email, password) => api.post('v1/auth/login', { email, password }),
  loginMobile: (data) => api.post('v1/auth/login/mobile', data),
  getUserData: id => api.get(`v1/users/${id}`),

  /* resetar senha */
  resetPassword: email => api.post('v1/auth/login/reset-password', { email }),
  confirmPasswordReset: (password, id) => api.post('v1/auth/login/confirm-reset', { password, id }),

  /* cadastro */
  validateEmail: emailAddress => api.get(`v1/users/${emailAddress}/validation`),
  updateEmail: data => api.put(`v1/users/email`, data),
  resendEmail: data => api.post(`v1/users/resend-email`, data),
  activeEmail: token => api.post(`v1/users/active/${token}`),
  signupWithEmail: signupData => api.post('v1/users', signupData),

  getCustomExpertises: () => api.get('v1/expertise/custom'),
  getExpertises: () => api.get('v1/expertise'),
  saveExpertises: data => api.post('v1/expertise', data),
  updateExpertises: (id, data) => api.put(`v1/expertise/${id}`, data),

  /* cadastro de espaço */

  saveRoomType: data => api.post('v1/rooms/type', data),
  updateRoomType: (id, data) => api.put(`v1/rooms/type/room/${id}`, data),
  getRoomType: id => api.get(`v1/rooms/type/room/${id}`),
  getRoomProgress: async id => api.get(`v1/rooms/progress/room/${id}`),

  getUserAddress: () => api.get('v1/rooms/address/profile'),
  getPlaceAddress: placeId => api.get(`v1/rooms/address/room/${placeId}`),
  getPlaceDistance: zipcode => api.post(`v1/rooms/address/geocode`, zipcode),
  savePlaceAddress: async ({ placeId, addressData, hosterProfileId }) =>
    api.post('v1/rooms/address/', {
      ...addressData,
      'room_id': placeId,
      'hoster_profile_id': hosterProfileId,
    }),
  updatePlaceAddress: (id, { placeId, addressData, hosterProfileId }) =>
    api.put(`v1/rooms/address/${id}`, {
      ...addressData,
      'room_id': placeId,
      'hoster_profile_id': hosterProfileId,
    }),

  getCalendarConfig: id => api.get(`v1/rooms/calendar-config/${id}/room`),
  updatePeriod: (placeId, data) => api.put(`v1/rooms/calendar-config/${placeId}/periods`, data),
  updateCalendarType: async (placeId, data) =>
    api.put(`v1/rooms/calendar-config/${placeId}/type`, data),
  updatePrice: async (placeId, price) =>
    api.put(`v1/rooms/calendar-config/${placeId}/price`, {
      price,
    }),
  updateDiscount: async (placeId, discount) =>
    api.put(`v1/rooms/calendar-config/${placeId}/discount`, {
      discount,
    }),
  getCalendarEvents: async placeId => api.get(`v1/rooms/calendar/${placeId}/room`),
  getReservedPeriods: async roomId => api.get(`v1/rooms/hasreservedperiods/${roomId}`),
  saveCalendarEvents: async (placeId, events) =>
    api.post(`v1/rooms/calendar`, {
      'room_id': placeId,
      periods: events,
    }),

  getBasicAmenities: placeId => api.get(`v1/rooms/convenience/room/${placeId}`),
  saveBasicAmenities: (placeId, amenities) =>
    api.post(`v1/rooms/convenience/${placeId}`, {
      title: amenities,
    }),
  getSecurityAmenities: placeId => api.get(`v1/rooms/security-convenience/room/${placeId}`),
  saveSecurityAmenities: (placeId, amenities) =>
    api.post(`v1/rooms/security-convenience/${placeId}`, {
      title: amenities,
    }),
  getEquipsAmenities: placeId => api.get(`v1/rooms/equips-convenience/room/${placeId}`),
  saveEquipsAmenities: (placeId, amenities) =>
    api.post(`v1/rooms/equips-convenience/${placeId}`, {
      title: amenities,
    }),
  uploadFile: file => {
    const form = new FormData();
    form.append('files', file);
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return api.post('v1/upload/multiple', form, { headers });
  },

  savePhotoRoom: async (id, data) => api.post(`v1/rooms/media/${id}`, data),
  updateOrderPhotoRoom: async (id, data) => api.put(`v1/rooms/media/${id}`, data),
  updatePhotoRoom: async (id, data) => api.put(`v1/rooms/media/${id}/edit`, data),
  getPhotosRoom: async id => api.get(`v1/rooms/media/room/${id}`),
  deletePhotoRoom: async id => api.del(`v1/rooms/media/${id}`),

  getPlaceTitle: async placeId => api.get(`v1/rooms/${placeId}/name`),
  updatePlaceTitle: async (placeId, title) => api.put(`v1/rooms/${placeId}/title`, title),
  getPlaceResume: async placeId => api.get(`v1/rooms/${placeId}/description`),
  updatePlaceResume: async (placeId, resume) => api.put(`v1/rooms/${placeId}/resume`, resume),
  getPlaceRules: async placeId => api.get(`v1/rooms/${placeId}/rules`),
  updatePlaceRules: async (placeId, rules) => api.put(`v1/rooms/${placeId}/rules`, rules),
  updatePlaceNotice: async (placeId, data) => api.put(`v1/rooms/${placeId}/notice`, data),
  getPlaceNotice: async placeId => api.get(`v1/rooms/${placeId}/notice`),

  // consulta de espaço
  getRooms: () => api.get('v1/rooms/profile'),
  getRoomById: id => api.get(`v1/rooms/${id}`),
  updateRoomStatus: (id, status) => api.put(`v1/rooms/${id}/status`, { status }),
  searchAdvertising: async (query) => api.get(`v1/search/room?${query}`),
  searchEquipments: async (query) => api.get(`v1/search/equipments?${query}`),
  searchAdvertisingPromotional: async (query) => api.get(`v1/search/promotional?${query}`),
  searchHighlightedAdvertising: async () => {
    await sharedCacheClient.prefetchQuery(
      'searchHighlightedAdvertising',
      () => api.get('v1/search/room/highlighted'),
      {
        staleTime: 0,
        cacheTime: 1,
      },
    );

    return sharedCacheClient.getQueryData('searchHighlightedAdvertising');
  },
  getAdvertisingDetail: id => api.get(`v1/search/room/${id}/detail`),
  getAdvertisingRatingDetail: id => api.get(`v1/rating/room/${id}/detail`),
  getAdvertisingComments: id => api.get(`v1/rating/room/${id}/comments`),

  // financial
  getReceiptsAccount: async () => api.get('v1/hoster-financial'),
  getHosterProfile: async () => api.get('v1/hoster-financial/profile'),
  saveReceiptsAccount: async data => api.post('v1/hoster-financial', data),
  saveReceiptsAddress: async data => api.post('v1/hoster-financial/address', data),
  getCreditCardBrands: async () => api.get('v1/brand-cards'),
  validateBin: async id => api.get(`v1/payment/bin/${id}`),

  /* eslint-disable camelcase */
  registerTokenPagarme: async data => {
    const pagarmeCard = {
      card_number: data.creditCard,
      card_holder_name: data.name,
      card_expiration_date: `${data.expiration.month}${data.expiration.year}`,
      card_cvv: data.cvv,
    };

    return new Promise(resolve => {
      pagarme.client
        .connect({
          encryption_key: process.env.NEXT_PUBLIC_PAGARME_CRYPT_KEY,
        })
        .then(client => client.security.encrypt(pagarmeCard))
        .then(card_hash => resolve([card_hash, false]))
        .catch(error => resolve([error, true]));
    });
  },
  saveCreditCard: async data => api.post('v1/booker-financial', data),
  getCreditCards: async () => api.get('v1/booker-financial/booker'),
  deleteCreditCard: async id => api.del(`v1/booker-financial/${id}`),
  saveAddressCreditCard: async (id, data) => api.put(`v1/booker-financial/${id}/add-address`, data),
  updateAddressCreditCard: async (id, data) =>
    api.put(`v1/booker-financial/${id}/edit-address`, data),

  // booking
  getBookings: async (profileId, type, query) =>
    api.get(`v1/booker-room/profile/${profileId}/${type}?${query}`),
  getBookingById: async id => api.get(`v1/booker-room/${id}`),
  bookingApprove: async id => api.post(`v1/booker-room/${id}/approve`, {}),
  bookingReprove: async id => api.post(`v1/booker-room/${id}/reprove`, {}),
  bookingCancel: async id => api.post(`v1/booker-room/${id}/cancel`, {}),

  // booking payment
  payBooking: async data => api.post('v1/booker-room', data),
  payStatus: async id => api.get(`v1/booker-room/${id}/transaction/status`),

  // payment refund
  getRefundByBooking: async id => api.get(`v1/payment/refund/booking/${id}`),

  // meeting
  createMeeting: meetingData => api.post('/meeting/create', meetingData, { baseURL: '/api/' }),
  getMeeting: meetingId => api.get(`/meeting/get/${meetingId}`, {}, { baseURL: '/api/' }),
  deleteMeeting: meetingId => api.del(`/meeting/delete/${meetingId}`, {}, { baseURL: '/api/' }),
  sendEmail: data => api.post('v1/hotsite/meeting/email', data),
  sendSms: data => api.post('v1/hotsite/meeting/sms', data),

  // notification
  updateNotificationStatus: notificationId =>
    api.put(`v1/notifications/${notificationId}/read`, {}),

  // rating
  getBookerRating: id => api.get(`v1/rating/booker/${id}`, {}, {}, true),
  saveBookerRating: (id, data) => api.put(`v1/rating/booker/${id}`, data),
  getRoomRating: id => api.get(`v1/rating/room/${id}`, {}, {}, true),
  saveRoomRating: (id, data) => api.put(`v1/rating/room/${id}`, data),
  updateRatingNotificationStatus: notificationId => api.put(`v1/rating/${notificationId}/read`, {}),
  google: { ...googleAPI(api) },
  social: { ...socialAPI(api) },
  nextjs: { ...nextjs(api) },

  /* coupon */
  createCoupon: coupon => api.post('v1/coupon', coupon),
  applyCoupon: coupon => api.post('v1/coupon/apply', coupon),
  enableCoupon: coupon => api.put('v1/coupon', coupon),
  getCoupons: roomId => api.get(`v1/coupon/${roomId}`),
  deleteCoupon: coupon => api.del(`v1/coupon/${coupon.hoster_room_id}/${coupon.code}`),

  /* concierge */
  saveConcierge: body => api.post('v1/concierge', body),
};
