import React from 'react';
import PropTypes from 'prop-types';
import NextHead from 'next/head';

const Head = ({ pageTitle }) => (
  <NextHead>
    <title>{pageTitle} - Let Clinic</title>
  </NextHead>
);

Head.propTypes = {
  pageTitle: PropTypes.string,
};
Head.defaultProps = {
  pageTitle: 'Let Clinic',
};

export default Head;
