import https from 'https';

export const GoogleMapsUrl = 'https://maps.googleapis.com/maps/api';
export const googleApiKey = 'AIzaSyDzhT0j1VtWH9MmZaIiywqWoieCcEDYqWg';

export const getRequest = url =>
  new Promise((resolve, reject) => {
    const req = https.get(url, res => {
      // reject on bad status
      if (res.statusCode < 200 || res.statusCode >= 300) {
        return reject(new Error(`statusCode=${res.statusCode}`));
      }
      // cumulate data
      let body = [];
      res.on('data', chunk => {
        body.push(chunk);
      });
      // resolve on end
      res.on('end', () => {
        try {
          body = JSON.parse(Buffer.concat(body).toString());
        } catch (e) {
          reject(e);
        }
        resolve(body);
      });
    });
    // reject on request error
    req.on('error', err => {
      // This is not a "Second reject", just a different sort of failure
      reject(err);
    });

    // IMPORTANT
    req.end();
  });

const nextjs = api => ({
  getPredictions: input =>
    api.get(`api/google/predictions?input=${input}`, undefined, { baseURL: '/' }),
  getPlaceDetail: id => api.get(`api/google/place?id=${id}`, undefined, { baseURL: '/' }),
});

export default nextjs;
