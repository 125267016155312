import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import LayoutLC from '@lc/ui/template/Layout';

import { NewTheme } from '@lc/legacy/src/Theme';

import ViewDetail from './components/ViewDetail';
import useStyles from './Layout.style';

const Layout = ({ children, overflowX, hideSearch, showFooter }) => {
  const bottomRef = useRef(null);
  const [viewDetail, setViewDetail] = useState();
  const classes = useStyles({
    overflowX,
  });

  return (
    <LayoutLC hideSearch={hideSearch} showFooter={showFooter}>
      <div className={clsx(classes.root)}>
        <main className={classes.main}>{children}</main>
        <NewTheme>
          {viewDetail && <ViewDetail detail={viewDetail} close={() => setViewDetail(undefined)} />}
          <div ref={bottomRef} />
        </NewTheme>
      </div>
    </LayoutLC>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  overflowX: PropTypes.bool,
  showFooter: PropTypes.bool,
  hideSearch: PropTypes.bool,
};

Layout.defaultProps = {
  overflowX: false,
  showFooter: true,
  hideSearch: false,
};

export default Layout;
