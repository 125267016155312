import apiLc from '@lc/lib/api';

import { FACEBOOK_ID, GOOGLE_ID, LINKEDIN_ID, SOCIAL_REDIRECT_URL } from 'config/socialNetworks';
import { isProduction } from 'config';

const saveLog = async (url, data) => {
  if (isProduction) {
    await apiLc.log.save(url, data);
  }
};

const social = api => ({
  getDataFromFB: async ({ code, FACEBOOK_SECRET }) => {
    const params = new URLSearchParams();
    params.set('client_id', FACEBOOK_ID);
    params.set('client_secret', FACEBOOK_SECRET);
    params.set('redirect_uri', SOCIAL_REDIRECT_URL);
    params.set('code', code);

    const [data, error] = await api.get(`oauth/access_token?${params.toString()}`, undefined, {
      baseURL: 'https://graph.facebook.com/',
    });
    await saveLog(`FROM https://graph.facebook.com/`, data);
    if (error) {
      return [data, true];
    }

    const result = await api.post('v1/auth/facebook', {
      access_token: data.access_token,
    });
    const [data2] = result;
    await saveLog(`FROM v1/auth/facebook`, data2);

    return result;
  },
  getDataFromGoogle: async ({ code, GOOGLE_SECRET }) => {
    const body = {
      'client_id': GOOGLE_ID,
      'client_secret': GOOGLE_SECRET,
      'redirect_uri': SOCIAL_REDIRECT_URL,
      'grant_type': 'authorization_code',
      'code': code,
    };
    const [data, error] = await api.post('token', body, {
      baseURL: 'https://oauth2.googleapis.com/',
    });

    await saveLog(`FROM https://oauth2.googleapis.com/`, data);

    if (error) {
      return [data, true];
    }

    const [data2, error2] = await api.post('v1/auth/google', {
      access_token: data.access_token,
    });

    await saveLog('FROM /v1/auth/google', data2);

    if (error2) {
      const [googleData] = await api.get('userinfo?alt=json', undefined, {
        baseURL: 'https://www.googleapis.com/oauth2/v1/',
        headers: { Authorization: `Bearer ${data.access_token}` },
      });
      await saveLog('FROM https://www.googleapis.com/oauth2/v1/', googleData);
      return [googleData, true];
    }
    return [data2, false];
  },

  getDataFromLinkedin: async ({ code, LINKEDIN_SECRET }) => {
    const params = new URLSearchParams({
      'client_id': LINKEDIN_ID,
      'client_secret': LINKEDIN_SECRET,
      'redirect_uri': SOCIAL_REDIRECT_URL,
      'grant_type': 'authorization_code',
      'code': code,
    });

    const [data, error] = await api.post(`accessToken?${params.toString()}`, undefined, {
      baseURL: 'https://www.linkedin.com/oauth/v2/',
      'Content-Type': 'x-www-form-urlencoded',
    });

    await saveLog(`FROM https://www.linkedin.com/oauth/v2/`, data);

    if (error) {
      return [data, true];
    }
    const result = await api.post('v1/auth/linkedin', {
      access_token: data.access_token,
    });
    const [data2] = result;
    await saveLog(`FROM v1/auth/linkedin`, data2);

    return result;
  },
});

export default social;
