import * as Sentry from '@sentry/nextjs';

export default {
  /**
   * @deprecated
   */
  log: (logger, key, message) => {
    Sentry.captureEvent({
      message: message || 'logger',
      level: 'log',
      logger,

      extra: { [key]: message },
    });
  },
  /**
   * @description Log error to sentry
   * @param {string} message
   * @param {object} data
   */
  logError: (message, data) => {
    const error = data?.error;
    Sentry.captureEvent({
      message,
      level: 'error',

      extra: {
        ...data,
        ...(error ? { errorStringify: JSON.stringify(error) } : {}),
      },
    });
  },
  /**
   * @description Log info to sentry
   * @param {string} message
   * @param {object} data
   */
  logInfo: (message, data) => {
    Sentry.captureEvent({
      message,
      level: 'info',

      extra: { ...data },
    });
  },
};
