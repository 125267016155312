/* eslint-disable prefer-destructuring */

export const SOCIAL_REDIRECT_URL = process.env.NEXT_PUBLIC_SOCIAL_REDIRECT_URL;

export const FACEBOOK_ID = process.env.NEXT_PUBLIC_FACEBOOK_ID;
export const FACEBOOK_SECRET = process.env.FACEBOOK_SECRET;

export const GOOGLE_ID = process.env.NEXT_PUBLIC_GOOGLE_ID;
export const GOOGLE_SECRET = process.env.GOOGLE_SECRET;

export const LINKEDIN_ID = process.env.NEXT_PUBLIC_LINKEDIN_ID;
export const LINKEDIN_SECRET = process.env.LINKEDIN_SECRET;
