export const GoogleMapsUrl = 'https://maps.googleapis.com/maps/api';
export const googleApiKey = 'AIzaSyDzhT0j1VtWH9MmZaIiywqWoieCcEDYqWg';

const google = api => ({
  getLatLngByZipCodeOrAddress: value =>
    api.get(
      `/geocode/json?address=${value}&sensor=false&language=pt-BR&key=${googleApiKey}`,
      {},
      { baseURL: GoogleMapsUrl },
    ),
  getGoogleMapsJsonGeocode: (latitude, longitude) =>
    api.get(
      `/geocode/json?latlng=${latitude},${longitude}&sensor=false&key=${googleApiKey}`,
      {},
      { baseURL: GoogleMapsUrl },
    ),getPredictions: input => api.get(`api/google/places?input=${input}`, undefined, { baseURL: '' }),
});

export default google;
